import React, { useEffect,  useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminChangeDriverDocumentsStatusApi, adminChangePartnerApprovalStatus, adminGetAllUserList, adminGetDriverDetailsById } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';
import { capitalizeWords, convertTo12HourFormat, dateFormat } from '../../../utils/HelperFuncitons';
import SelectedUserDetail from '../../common/SelectedUserDetail';
import { S3_URL } from '../../../utils/BaseUrl';
import ConfirmationModal from '../../common/ConfirmationModal';
import PdfSvgIcon from '../../common/PdfSvgIcon';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import toast from 'react-hot-toast';
import moment from 'moment';
import CommonTable from '../../common/Table/CommonTable';

const DriverDetails = (props) => {
    const [allDrivers, setAllDrivers] = useState([])
    const params = useParams();
    // const location = useLocation();
    // const path = location.pathname.split("/")
    const [id, setId] = useState(params?.id)
    const [driverDetails, setDriverDetails] = useState({});
    const [vehicleDetails, setVehicleDetails] = useState([]);
    const [bankDetails, setBankDetails] = useState([]);
    const [bonusDetails, setBonusDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [activeTab, setActiveTab] = useState('driver');
    const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "", role: "driver" });
    const [selectedImg, setSelectedImg] = useState("")
    const confirmationModalId2 = "confirmationModal2"
    const [keyNameForUpdate, setKeyNameForUpdate] = useState("")
    const [selectDocDate, setSelectDocDate] = useState("")

    const fetchDriverDetails = async () => {
        try {
            const res = await adminGetDriverDetailsById(id);
            if (res.status === 200) {
                const { id, role, phone, driverDetails, partnerUserDetails, vehicles, bankDetails, driverBonus } = res.data.data;
                setDriverDetails({ id, phone, role, ...driverDetails[0], ...partnerUserDetails[0] });
                setVehicleDetails(vehicles);
                setBankDetails(bankDetails)
                setBonusDetails(driverBonus)
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    const getAllDrivers = async () => {
        try {
            const res = await adminGetAllUserList("", "", "", "driver");
            if (res.status === 200) {
                setAllDrivers(res.data?.data?.findPartners)
            }
        } catch (error) {

        }
    }

    const handleChangeStatus = async () => {
        try {
            setLoader(true)
            await adminChangePartnerApprovalStatus(confirmStatus)
            fetchDriverDetails();
            document.getElementById('confirmationModal').click()
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something Went Wrong")
            setLoader(false)
        }
    }

    const openModal = () => {
        document.getElementById('confirmationModal2Btn').click()
    }

    useState(() => {
        getAllDrivers();
    }, [])

    useEffect(() => {
        if (id) {
            fetchDriverDetails();
        }
    }, [id]);

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const handleChangeDocumentStatus = async (status, keyName) => {
        const promise = adminChangeDriverDocumentsStatusApi({ userId: id, keyName: keyName, status: status });
        toast.promise(
            promise,
            {
                loading: 'Updating document status...',
                success: (res) => {
                    if (res.status === 200) {
                        let driverDetailsTemp = { ...driverDetails }
                        if (keyName === "licenceApprovalStatus" || keyName === "profilePhotoApprovalStatus" || keyName === "nonConvictionCertificateApprovalStatus") {
                            driverDetailsTemp[keyName] = status
                        } else if (keyName === "vehicleCertificateApprovalStatus" || keyName === "vehicleInsuranceApprovalStatus" || keyName === "vehicleImageApprovalStatus") {
                            let vehicleDetailsTemp = [...vehicleDetails]
                            if (vehicleDetailsTemp && vehicleDetailsTemp.length) {
                                vehicleDetailsTemp[0][keyName] = status
                            }
                            setVehicleDetails(vehicleDetailsTemp)
                        }

                        if(res.data?.driverStatus){
                            driverDetailsTemp["driverStatus"] = res.data.driverStatus
                        }
                        setDriverDetails(driverDetailsTemp)
                        return res.data.message || 'Success';
                    } 
                },
                error: (err) => {
                    return err.message || 'Something Went Wrong'
                }
            }
        )
    };

    const DocumentDisplay = ({ title, document, setSelectedImg, status, keyName, validUpto }) => {
        if (!document) {
            return (
                <tr>
                    <td>{title}</td>
                    <td className='text-center'>{`${title} not available`}</td>
                </tr>
            );
        }

        const isPdf = document ? document.split(".").slice(-1)[0] === "pdf" : "";

        return (
            <tr>
                <td>{title}</td>
                <td className='text-center'>
                    {isPdf ? (
                        <Link to={`${S3_URL}/${document}`} className="text-primary">
                            <PdfSvgIcon width="100" height="100" />
                        </Link>
                    ) : (
                        <img
                            src={`${S3_URL}/${document}`}
                            alt={title}
                            data-bs-toggle="modal"
                            data-bs-target={`#imageViewModal`}
                            style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                            onClick={() => setSelectedImg(`${S3_URL}/${document}`)}
                        />
                    )}
                </td>
                <td className='text-center'>{validUpto ? dateFormat(validUpto) : ""}</td>
                <td className='text-center'>{ capitalizeWords(status) || "-"}</td>
                {status !== "pending" && <td className='text-center'>
                    <div className="dropdown">
                        <button
                            className=" border-0 bg-white"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                            >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </button>
                        <ul
                            className="dropdown-menu"
                            style={{ zIndex: "1000" }}
                        >
                            {(status === "rejected" || status === "submitted") && <li
                                onClick={() =>{
                                    setSelectDocDate(validUpto);
                                    if(moment().toISOString() < moment(validUpto).toISOString()){
                                        handleChangeDocumentStatus('approved', keyName)
                                    }else {
                                        setKeyNameForUpdate(keyName)
                                        openModal()
                                    }
                                }}
                            >
                                <a className="dropdown-item" href="#">
                                    Approve
                                </a>
                            </li>}

                            {(status === "approved" || status === "submitted") && <li
                                onClick={() =>
                                    handleChangeDocumentStatus('rejected', keyName)
                                }
                            >
                                <a className="dropdown-item" href="#">
                                    Reject
                                </a>
                            </li>}
                        </ul>
                    </div>
                </td>}
            </tr>
        );
    };

   

    const vehicleHeaderData = [
        { name: "Registration Number", className: "list-sort text-muted", key: "licenseNo" },
        { name: "Year", className: "list-sort text-muted", key: "year" },
        { name: "Class", className: "list-sort text-muted", key: "category" },
        { name: "Type", className: "list-sort text-muted", key: "make" },
        { name: "Model", className: "list-sort text-muted", key: "model" },
        { name: "Color", className: "list-sort text-muted", key: "color" },
        { name: "Registration Number", className: "list-sort text-muted", key: "vehicleNumber" },
        { name: "Vehicle Certificate", className: "list-sort text-muted", key: "vehicleCertificate" },
        { name: "Insurance", className: "list-sort text-muted", key: "vehicleInsurance" },

    ]

    const vehicleTableData = vehicleDetails.map((vhcl, index) => {
        return {
            licenseNo: vhcl.licenseNo,
            year: vhcl.year,
            category: vhcl?.category?.category,
            make: vhcl?.make?.make,
            model: vhcl?.model?.model,
            color: capitalizeWords(vhcl?.color) || "",
            vehicleNumber: vhcl?.vehicleNumber || "",
            vehicleCertificate: vhcl?.vehicleCertificate?.split(".")?.slice(-1)?.includes("pdf") ?
                <Link to={`${S3_URL}/${vhcl?.vehicleCertificate}`}  > <PdfSvgIcon width="100" height="100" /></Link>
                :
                <img
                    src={vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
                    alt={"img"}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageViewModal`}
                    style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                    onClick={() => { setSelectedImg(vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
                />,
            vehicleInsurance: vhcl?.vehicleInsurance?.split(".")?.slice(-1)?.includes("pdf") ?
                <Link to={`${S3_URL}/${vhcl?.vehicleInsurance}`}> <PdfSvgIcon width="100" height="100" /></Link>
                :
                <img
                    src={vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
                    alt={"img"}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageViewModal`}
                    style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                    onClick={() => { setSelectedImg(vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
                />
        }
    })


    const bankDetailsHeaderData = [
        { name: "Account Holder Namer", className: "list-sort text-muted", key: "name" },
        { name: "Bank Name", className: "list-sort text-muted", key: "bankName" },
        { name: "IFSC", className: "list-sort text-muted", key: "Ifsc" },
        { name: "Account Number", className: "list-sort text-muted", key: "accountNo" },
        { name: "Account Type", className: "list-sort text-muted", key: "accountType" },
        
    ]

    const bankDetailsTableData = bankDetails.map((bnk, index) => ({
        name: driverDetails.name || "",
        bankName: bnk.name || "",
        Ifsc: bnk.Ifsc || "",
        accountNo: bnk.accountNo || "",
        accountType: bnk.accountType || "",
    }))

    const bonusHeaderData = [
        { name: "Name", className: "list-sort text-muted", key: "name" },
        { name: "Duration", className: "list-sort text-muted", key: "duration" },
        { name: "Amount", className: "list-sort text-muted", key: "amount" },
        { name: "Date", className: "list-sort text-muted", key: "updatedAt" },
    ]

    const bonusTableData = bonusDetails.map((bonus, index) => ({
        name: bonus?.bonus?.name || "",
        duration: `${capitalizeWords(bonus?.bonus?.duration) || ""}${bonus?.bonus?.duration === "period" ? ` (${convertTo12HourFormat(bonus?.bonus?.from)} - ${convertTo12HourFormat(bonus?.bonus?.to)})` : ""}`,
        amount: bonus?.bonus?.amount || "",
        updatedAt: bonus?.updatedAt ? dateFormat(bonus?.updatedAt) : ""
    }))


    const profileData = [
        { name: "Email", data: driverDetails.email, },
        { name: "Phone", data: driverDetails.phone, },
        { name: "Alternate Phone", data: driverDetails.alternateMobile, },
        { name: "Emergency Contact", data: driverDetails.emergencyContact, },
        { name: "NIN", data: driverDetails.NIN, },
        { name: "Status", data: capitalizeWords(driverDetails.driverStatus), },
        { name: "Role", data: driverDetails.role?.map(el => capitalizeWords(el))?.join(", "), },
        { name: "Gender", data: driverDetails.gender, },
        { name: "City", data: driverDetails.city, },
        { name: "State", data: driverDetails.state, },
        { name: "Country", data: driverDetails.country, },
        { name: "Zip Code", data: driverDetails.zip, },
        { name: "License Number", data: vehicleDetails[0]?.licenseNo || "", },
        { name: "Driving Experience", data: (driverDetails.drivingExp || 0) + " years", },
        { name: "Address", data: <div>{driverDetails.permanentAddress && `${driverDetails.permanentAddress}, `}{driverDetails.state && `${driverDetails.state}, `}{driverDetails.city && `${driverDetails.city}, `}{driverDetails.zip && `${driverDetails.zip}`}</div>, },

    ]


    const tabData = [
        {
            name: "Profile Details", tab: "driver", setTab: toggleTab, data: <div className="row card">
                <div className="row mt-3">
                    <div className="user-details">
                        <table className="table table-md table-hover card-table">
                            <tbody>
                                {
                                    profileData.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{ele.name}:</td>
                                            <td>{ele.data}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        },
        { name: "Vehicle Details", tab: "vehicle", setTab: toggleTab, data: <div className="card row" ><CommonTable headers={vehicleHeaderData} data={vehicleTableData} /></div> },
        { name: "Bank Details", tab: "bankDetails", setTab: toggleTab, data: <div className="card row" ><CommonTable headers={bankDetailsHeaderData} data={bankDetailsTableData} /></div> },
        { name: "Bonus Details", tab: "bonus", setTab: toggleTab, data:  <div className="card row" ><CommonTable headers={bonusHeaderData} data={bonusTableData} /> </div>},
        {
            name: "Documents", tab: "document", setTab: toggleTab, data: <div className="row card ">
                <div className="table-responsive  " style={{ overflowX: "inherit" }}>
                    <table className="table table-md table-hover card-table">
                        <thead>
                            <tr>
                                <th> <span className="list-sort text-muted">Type</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">File</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Valid Upto</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Status</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <DocumentDisplay
                                title="License"
                                document={driverDetails?.licenceImage}
                                setSelectedImg={setSelectedImg}
                                status={driverDetails?.licenceApprovalStatus}
                                keyName={"licenceApprovalStatus"}
                                validUpto={driverDetails?.licenseCertificateValidUpto}
                            />
                            <DocumentDisplay
                                title="Profile Image"
                                document={driverDetails?.profileImage}
                                setSelectedImg={setSelectedImg}
                                status={driverDetails?.profilePhotoApprovalStatus}
                                keyName={"profilePhotoApprovalStatus"}
                            />
                            {driverDetails?.criminalBackground && (
                                <DocumentDisplay
                                    title="Non-Conviction Certificate"
                                    document={driverDetails?.nonConvictionCertificate}
                                    setSelectedImg={setSelectedImg}
                                    status={driverDetails?.nonConvictionCertificateApprovalStatus}
                                    keyName={"nonConvictionCertificateApprovalStatus"}
                                />
                            )}
                            <DocumentDisplay
                                title="Vehicle Registration Certificate"
                                document={vehicleDetails[0]?.vehicleCertificate}
                                setSelectedImg={setSelectedImg}
                                status={vehicleDetails[0]?.vehicleCertificateApprovalStatus}
                                keyName={"vehicleCertificateApprovalStatus"}
                                validUpto={vehicleDetails[0]?.vehicleCertificateValidUpto}

                            />
                            <DocumentDisplay
                                title="Vehicle Insurance"
                                document={vehicleDetails[0]?.vehicleInsurance}
                                setSelectedImg={setSelectedImg}
                                status={vehicleDetails[0]?.vehicleInsuranceApprovalStatus}
                                keyName={"vehicleInsuranceApprovalStatus"}
                                validUpto={vehicleDetails[0]?.insuranceCertificateValidUpto}
                            />
                            <DocumentDisplay
                                title="Vehicle Image"
                                document={vehicleDetails[0]?.vehicleImage}
                                setSelectedImg={setSelectedImg}
                                status={vehicleDetails[0]?.vehicleImageApprovalStatus}
                                keyName={"vehicleImageApprovalStatus"}
                            />
                        </tbody>
                    </table>
                </div>
            </div> },
    ]    

    return (
        <div className="main-content">
            <div className="container-fluid">

                {/* <SelectedUserDetail
                    allDropdownData={allDrivers}
                    selectedUser={driverDetails}
                    userId={id}
                    setUserId={setId}
                    type="Driver"
                /> */}
                {loader ? (
                    <div className="text-center">
                        <Spinwheel />
                    </div>
                ) : (
                    <>
                        <div className="row align-items-end">
                            <div className="col-auto">
                                <div className="avatar avatar-xxl header-avatar-top mt-3">
                                    <img src={driverDetails?.profileImage ? `${S3_URL}/${driverDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                                </div>
                            </div>
                            <div className="col mb-3 ms-n3 ms-md-n2">
                                <h1 className="header-title">
                                    {capitalizeWords(driverDetails.name)}
                                </h1>
                            </div>
                            <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                                {(driverDetails.driverStatus === "pending") ? <><button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal"
                                    data-bs-target={`#confirmationModal`}
                                    onClick={(e) => { setConfirmStatus( prevState => ({...prevState, status: "approved", userId: id })) }}>Approve</button>
                                    <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal"
                                        data-bs-target={`#confirmationModal`}
                                        onClick={(e) => { setConfirmStatus( prevState => ({...prevState, status: "rejected", userId: id })) }}>Reject</button></> :
                                    <div className={`badge fs-4  ${driverDetails.driverStatus === "approved" ? "bg-primary-soft" : "bg-secondary-soft"}`}>{capitalizeWords(driverDetails.driverStatus)}</div>
                                }
                            </div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <ul className="nav nav-tabs">
                                {
                                    tabData.map((ele,index) => (
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === ele.tab ? 'active' : ''}`}
                                                onClick={() => ele.setTab(ele.tab)}
                                            >
                                                {ele.name}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div>
                            </div>
                        </div>

                            {
                                tabData.map((ele, index) => (
                                    activeTab === ele.tab ? ele.data : null
                                ))
                            }
                        </>
                )}

                {<ConfirmationModal
                    handleConfirm={handleChangeStatus}
                    confirmStatus={confirmStatus}
                />}
                <ImageViewModal imgUrl={selectedImg} modalId={"imageViewModal"} />
                {console.log(selectDocDate)}
                <button id="confirmationModal2Btn" className='d-none'data-bs-toggle="modal"data-bs-target={`#${confirmationModalId2}`}></button>
                <ConfirmationModal modalId={confirmationModalId2} message={<>The document is already expired!<br/> Do you still want to continue?</>} handleConfirm={() => handleChangeDocumentStatus("approved", keyNameForUpdate)} title={"Warning!"} confirmStatus={""}/>
            </div>
        </div>

    );
};

export default DriverDetails;
