import React, { useEffect, useState } from 'react'
import AppVersionForm from './AppVersionForm';
import { useSearchParams } from 'react-router-dom';
import Spinwheel from '../../common/Spinwheel';
import toast from 'react-hot-toast';
import CountryAndCites from './CountryAndCites';
import { arrayMove, List } from "react-movable";
import { useFormik } from 'formik';
import { adminBannerInitialValues, adminBannerInitialValuesValidationSchema } from '../../common/Validation';
import BannerModal from '../../common/Modal/BannerModal';
import { addOrUpdateAppBannerImagesApi, deleteAppBannerImageApi, getAllAppBannerImagesApi, updateAppBannerSerialNoApi } from '../../../utils/ApiEndpointFunctions';
import { S3_URL } from '../../../utils/BaseUrl';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import DeletedModal from '../../common/Modal/DeletedModal';

const GlobalSetting = () => {
    const [activeTab, setActiveTab] = useState("")
    const [addLoader, setAddLoader] = useState(false)
    const addBannerModalId = "add-banner-modal-id";
    const imageViewModalId = "image-view-modal-id";
    const [driverBanner, setDriverBanner] = useState([])
    const [hireBanner, setHireBanner] = useState([])
    const [restaurantBanner, setRestaurantBanner] = useState([])
    const [userBanner, setUserBanner] = useState([])
    const [loader, setLoader] = useState({driver: true, hire: true, restaurant: true, user: true})
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedImg, setSelectedImg] = useState("");
    const deleteModalId = "deleteBannerModalId"

    const toggleTab = (tab) => {
        setActiveTab(tab);
        setSearchParams({ tab: tab });
    }


    const handleOrderChanged = async ({ oldIndex, newIndex }, type) => {
        const newArray = arrayMove(
            type === "driver" ? driverBanner 
                : type === "hire" ? hireBanner 
                : type === "restaurant" ? restaurantBanner
                : userBanner,
            oldIndex,
            newIndex
        );

        type === "driver" ? setDriverBanner(newArray) 
            : type === "hire" ? setHireBanner(newArray) 
                : type === "restaurant" ? setRestaurantBanner(newArray)
                    : setUserBanner(newArray)

        let payload = newArray.map(ele => ele.id);

        try {
            const res = await updateAppBannerSerialNoApi({ bannerIds: payload });
            if (res.status === 200) {
                toast.success(res.data?.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    };

    const getAllAppBannerImages = async (type) => {
        setLoader((previous) => {
            return { ...previous, [type]: false }
        })
        try {
            const res = await getAllAppBannerImagesApi(type);
            if(res.status === 200) {
                console.log(type, res.data)
                if(type === 'driver') {
                    setDriverBanner(res.data?.data);
                } else if(type === 'hire') {
                    setHireBanner(res.data?.data);
                } else if(type ==='restaurant') {
                    setRestaurantBanner(res.data?.data);
                } else if(type ==='user') {
                    setUserBanner(res.data?.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoader((previous) => {
            return {...previous, [type]: true}
        })
    }  

    useEffect(() => {
        if (searchParams.get("tab") === 'banner') {
            getAllAppBannerImages('driver');
            getAllAppBannerImages('hire');
            getAllAppBannerImages('restaurant');
            getAllAppBannerImages('user');
        }           
    }, [searchParams])

    const handleSubmit = async (values) => {
        try {
            setAddLoader(true)
            const res = await addOrUpdateAppBannerImagesApi(values);
            if (res.status === 200) {
                toast.success(res.data?.message)
                getAllAppBannerImages(values.type);
                document.getElementById(addBannerModalId).click();
            }
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
        setAddLoader(false)
    }

    const removeBannerHandler = async () => {
        try {
            setAddLoader(true)
            const res = await deleteAppBannerImageApi({ id: formik.values.id });
            if (res.status === 200) {
                toast.success(res.data?.message)
                getAllAppBannerImages(formik.values.type);
                document.getElementById(deleteModalId).click();
            }
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
        setAddLoader(false)
    }


    const formik = useFormik({
        initialValues: adminBannerInitialValues,
        validationSchema: adminBannerInitialValuesValidationSchema,
        onSubmit: handleSubmit
    })




    const BannerTable = ({ tableData, tableSpin, tableTitle, type }) => {
        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
                            <h1>{tableTitle}</h1>
                            <div style={{ display: "-webkit-inline-box" }}>
                                <div className="position-relative my-3">
                                    <div className="d-flex gap-2 justify-content-end align-items-center">

                                        <button
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target={`#${addBannerModalId}`}
                                            onClick={() => {
                                                formik.resetForm();
                                                formik.setFieldValue("type", type)
                                            }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <List
                                values={tableData}
                                onChange={({ oldIndex, newIndex }) => {
                                    handleOrderChanged({ oldIndex, newIndex }, type)
                                }}
                                renderList={(item) => {
                                    return (
                                        <table className="table table-hover table-nowrap card-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className="list-sort text-muted">S.No</span>
                                                    </th>
                                                    <th>
                                                        <span className="list-sort text-muted">File</span>
                                                    </th>
                                                    <th>
                                                        <span className="list-sort text-muted">Description</span>
                                                    </th>
                                                    <th>
                                                        <span className="list-sort text-muted">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody {...item?.props} className="list fs-base">
                                                {tableSpin ? (
                                                    tableData?.length > 0 ? (
                                                        item?.children
                                                    ) : (
                                                        <tr align="center">
                                                            <td colSpan="12">
                                                                <h1>Data Not Found</h1>
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr align="center">
                                                        <td colSpan="12">
                                                            <Spinwheel />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    );
                                }}
                                renderItem={(ele) => {
                                 
                                    return (
                                        <tr {...ele.props} key={ele?.index}
                                            style={{ ...ele.props.style, zIndex: undefined }}
                                        >
                                            <td data-movable-handle>{ele.index + 1}</td>
                                            <td className="position-relative">
                                                <div className="avatar avatar-xs align-middle me-2">
                                                    <img
                                                        className="avatar-img rounded-circle"
                                                        src={!ele.value.image ? "/images/profiledp.png" : `${S3_URL}/${ele.value?.image}`}
                                                        alt="img"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#${imageViewModalId}`}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { setSelectedImg(!ele.value.image ? "/images/profiledp.png" : `${S3_URL}/${ele.value?.image}`) }}
                                                    />
                                                </div>
                                            </td>

                                            <td className="position-relative">
                                                <span
                                                    className="item-name text-reset"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {ele?.value?.description ? ele?.value?.description : ""}
                                                </span>
                                            </td>

                                            <td className="text-start ">
                                                <div className="dropdown dropstart">
                                                    <span
                                                        className="dropdown-ellipses text-dark"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="fe fe-more-vertical" />
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <div
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#${addBannerModalId}`}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => { formik.setValues({id: ele.value.id, description: ele.value.description, image: ele.value.image, type: ele.value.type}) }}
                                                        >
                                                            Edit
                                                        </div>
                                                        <div
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#${deleteModalId}`}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => { formik.setValues({id: ele.value.id, description: ele.value.description, image: ele.value.image, type: ele.value.type}) }}
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>

            </>
        )
    }

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className='d-flex justify-content-between'>
                    {searchParams.get("tab") !== "appversion" && <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${(activeTab === 'servicearea' || searchParams.get("tab") == null)? 'active' : ''}`}
                                onClick={() => toggleTab('servicearea')}
                            >
                                Service Area
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${searchParams.get("tab") === 'banner' ? 'active' : ''}`}
                                onClick={() => toggleTab('banner')}
                            >
                                Banners
                            </button>
                        </li>
                    </ul>}
                </div>

                {(searchParams.get("tab") === "servicearea" || searchParams.get("tab") == null) && <>
                    <CountryAndCites />
                </>}

                {searchParams.get("tab") === 'appversion' && (
                    <AppVersionForm />
                )}

                {searchParams.get("tab") === 'banner' && (
                    <div>
                        <BannerTable tableData={driverBanner} tableSpin={loader.driver} tableTitle={"Driver"} type="driver" />
                        <hr />
                        <BannerTable tableData={hireBanner} tableSpin={loader.hire} tableTitle={"Hire"} type={"hire"} />
                        <hr />
                        <BannerTable tableData={restaurantBanner} tableSpin={loader.restaurant} tableTitle={"Restaurant"} type={"restaurant"} />
                        <hr />
                        <BannerTable tableData={userBanner} tableSpin={loader.user} tableTitle={"User"} type={"user"} />
                    </div>
                )}
            </div>

            <BannerModal formik={formik} addLoader={addLoader} modalId={addBannerModalId} />
            <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
            
            <DeletedModal
            modalId={deleteModalId}
            message={"This Banner"}
            deletedHandler={removeBannerHandler}
            loader={addLoader}
        />
        </div>
    )
}

export default GlobalSetting