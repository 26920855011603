import * as Yup from 'yup'

// admin sign in
export const SignInInitaialValue = {
  email: "",
  password: "",
  checkbox: false
}

export const SignInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  // checkbox:Yup.bool().oneOf([true], 'must be checked').required()
})


export const forgetPasswordIntial = {
  email: "",
  otp: undefined
}

export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  otp: Yup.number("Otp should be number").required("Otp is required"),
})

export const adminInitial = {
  email: "",
  password: ""
}

export const adminValidationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required("Password is required")
  // .min(8, 'Password should be minimum 8 character'),
})

export const profileInitialValue = Yup.object({

  firstName: "",
  lastName: "",
  profilePic: ""
})


export const rideInitialValue = {
  // vehicleId:"",
  make: "",
  model: "",
  type: "",
  standardPrice: "",
  peakHourPrice: "",
  category: ""
}

export const rideValidationSchema = Yup.object().shape({
  // vehicleId: Yup.string().trim().required('Vehicle is required'),
  make: Yup.string().trim().required('Make is required'),
  model: Yup.string().trim().required('Model is required'),
  type: Yup.string().trim().required('Type is required'),
  standardPrice: Yup.number("Standard Price must be number.").required('Standard Price is required'),
  peakHourPrice: Yup.number("Peak Hour Price must be number.").required('Peak Hour Price is required'),
  category: Yup.string().trim().required('Category is required'),
});

export const addCategoryInitialValue = {
  category: [],
  cityId: [],
  countryId: "",
}

export const addCategoryValidationSchema = Yup.object().shape({
  countryId: Yup.mixed().required('country is required'),

  cityId: Yup.array()
  .of(Yup.object().shape({
    label: Yup.string().required('City is required'),
    value: Yup.string().required('City is required'),
  }))
  .min(1, 'At least one city is required')
  .required('City is required'),

  category: Yup.array()
  .of(Yup.object().shape({
    label: Yup.string().required('Category is required'),
    value: Yup.string().required('Category is required'),
  }))
  .min(1, 'At least one city is required')
  .required('City is required'),

})

export const categoryInitialValue = {
  image:"",
  category: "",
  baseRate: "",
  perMiles: "",
  perMinute: "",
  surcharge: 0,
  rideBookingFee: "",
  waitFee: "",
  cancellationFee: "",
  platformFee: "",
  otherStopFee: "",
  cityId: "",
  countryId: "",
  cityTax: "",
  weather:"",
  promotionalDiscountFee:0,
  isFlash: false,
  isCommission: false,
  isSubscription: false,
  isWeather:false,
  noOfRides:0,
  subscriptionFee: 0,
  commissionFee: 0,
  city:{},
  country:{}
}

export const categoryValidationSchema = Yup.object().shape({
  category: Yup.string().trim().required('Category is required'),

  baseRate: Yup.number()
    .typeError('Base rate must be a number')
    .positive('Base rate must be a positive number')
    .required('Base rate is required'),

  perMiles: Yup.number()
    .typeError('Per Km must be a number')
    .positive('Per Km must be a positive number')
    .required('Per Km is required'),

  perMinute: Yup.number()
    .typeError('Per minute must be a number')
    .positive('Per minute must be a positive number')
    .required('Per minute is required'),

  surcharge: Yup.number()
    .typeError('Surcharge must be a number')
    .required('Surcharge is required'),

  rideBookingFee: Yup.number()
    .typeError('Ride booking fee must be a number')
    .positive('Ride booking fee must be a positive number')
    .required('Ride booking fee is required'),

  waitFee: Yup.number()
    .typeError('Wait fee must be a number')
    .positive('Wait fee must be a positive number')
    .required('Wait fee is required'),

  cancellationFee: Yup.number()
    .typeError('Cancellation fee must be a number')
    .positive('Cancellation fee must be a positive number')
    .required('Cancellation fee is required'),

  platformFee: Yup.number()
    .typeError('Platform fee must be a number')
    .positive('Platform fee must be a positive number')
    .required('Platform fee is required'),

  cityId: Yup.mixed().required('city is required'),
  countryId: Yup.mixed().required('country is required'),

  otherStopFee: Yup.number()
    .typeError('Other stop fee must be a number')
    .positive('Other stop fee must be a positive number')
    .required('Other stop fee is required'),

  cityTax: Yup.number()
    .typeError('City tax must be a number')
    .positive('City tax must be a positive number')
    .required('City tax is required'),

  weather: Yup.number()
    .typeError('Weather must be a number')
    .positive('Weather must be a positive number')
    .required('Weather is required'),

    promotionalDiscountFee: Yup.number()
    .typeError('Promotional discount fee must be a number')
    .positive('Promotional discount fee must be a positive number')
    .required('Promotional discount fee is required')
    .min(0, 'Number of rides must be a positive number or zero'),

    subscriptionFee: Yup.number()
    .typeError('Promotional discount fee must be a number')
    .positive('Promotional discount fee must be a positive number')
    .required('Promotional discount fee is required')
    .min(0, 'Number of rides must be a positive number or zero'),

    commissionFee: Yup.number()
    .typeError('Promotional discount fee must be a number')
    .positive('Promotional discount fee must be a positive number')
    .required('Promotional discount fee is required')
    .min(0, 'Number of rides must be a positive number or zero'),

  isFlash: Yup.boolean(),

  noOfRides: Yup.number()
    .typeError('Number of rides must be a number')
    .integer('Number of rides must be an integer'),
  
  isCommission: Yup.boolean().test('isCommission', 'At least one of Commission or Subscription must be true', function(value) {
    if(value || this.parent.isSubscription) return true;
    return false
  }),
  isSubscription: Yup.boolean().test('isCommission', 'At least one of Commission or Subscription must be true', function(value) {
    if(value || this.parent.isCommission) return true;
    return false
  })

})

export const defaultCategoryValidationSchema = Yup.object().shape({
  category: Yup.string().trim().required('Category is required'),

  baseRate: Yup.number()
    .typeError('Base rate must be a number')
    .positive('Base rate must be a positive number')
    .required('Base rate is required'),

  perMiles: Yup.number()
    .typeError('Per Km must be a number')
    .positive('Per Km must be a positive number')
    .required('Per Km is required'),

  perMinute: Yup.number()
    .typeError('Per minute must be a number')
    .positive('Per minute must be a positive number')
    .required('Per minute is required'),

  surcharge: Yup.number()
    .typeError('Surcharge must be a number')
    .required('Surcharge is required'),

  rideBookingFee: Yup.number()
    .typeError('Ride booking fee must be a number')
    .positive('Ride booking fee must be a positive number')
    .required('Ride booking fee is required'),

  waitFee: Yup.number()
    .typeError('Wait fee must be a number')
    .positive('Wait fee must be a positive number')
    .required('Wait fee is required'),

  cancellationFee: Yup.number()
    .typeError('Cancellation fee must be a number')
    .positive('Cancellation fee must be a positive number')
    .required('Cancellation fee is required'),

  platformFee: Yup.number()
    .typeError('Platform fee must be a number')
    .positive('Platform fee must be a positive number')
    .required('Platform fee is required'),

  otherStopFee: Yup.number()
    .typeError('Other stop fee must be a number')
    .positive('Other stop fee must be a positive number')
    .required('Other stop fee is required'),

  cityTax: Yup.number()
    .typeError('City tax must be a number')
    .positive('City tax must be a positive number')
    .required('City tax is required'),

  weather: Yup.number()
    .typeError('Weather must be a number')
    .positive('Weather must be a positive number')
    .required('Weather is required'),

  noOfRides: Yup.number()
    .typeError('Number of rides must be a number')
    .integer('Number of rides must be an integer'),

  isCommission: Yup.boolean().test('isCommission', 'At least one of Commission or Subscription must be true', function (value) {
    if (value || this.parent.isSubscription) return true;
    return false
  }),
  isSubscription: Yup.boolean().test('isCommission', 'At least one of Commission or Subscription must be true', function (value) {
    if (value || this.parent.isCommission) return true;
    return false
  })

});

export const ridesInitialValue = {
  make: "",
  model: "",
  categoryId: ""
}

export const ridesValidationSchema = Yup.object().shape({
  make: Yup.string().trim().required('Make is required'),
  model: Yup.string().trim().required('Model is required'),
  categoryId: Yup.number().required('Category is required')
})

export const cuisineOutletInitialValue = {
  name: "",
  image: ""
}

export const cuisineOutletValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  image: Yup.string().trim().required('Image is required'),
});

export const cityInitialValue = {
  cityName: "",
  place_id: "",
  cityTax: ""
}

export const cityValidationSchema = Yup.object().shape({
  cityName: Yup.string().trim().required('Name is required'),
  place_id: Yup.string().trim().required('place_id is required'),
  cityTax: Yup.number()
    .typeError('Tax must be a number')
    .positive('Tax must be a positive number')
    .required('Tax is required')
});

export const surchargePeriodInitialValue = {
  isEnabled: true,
  periodName: "",
  to: "",
  from: "",
  multiplier: "",
  // cityId: "",
  // countryId: "",
}

export const surchargePeriodValidationSchema = Yup.object().shape({
  periodName: Yup.string().trim().required('Name is required'),
  to: Yup.string().trim().required('To is required'),
  from: Yup.string().trim().required('From is required'),
  multiplier: Yup.number()
    .typeError('Tax must be a number')
    .positive('Tax must be a positive number')
    .required('Tax is required'),
  // cityId: Yup.mixed().required('city is required'),
  // countryId: Yup.mixed().required('country is required'),
});

export const bonusInitialValue = {
  name: "",
  noOfTrips: "",
  duration: "",
  from: "",
  to: "",
  minimumRating: "",
  amount:""
}

export const bonusValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  noOfTrips: Yup.number()
    .typeError('No of Trips must be a number')
    .positive('No of Trips must be a positive number')
    .required('No of Trips is required'),
  duration: Yup.object().shape({
    value: Yup.string().required('Duration is required'),
    label: Yup.string().required('Duration is required'),
  }),
  minimumRating: Yup.number()
    .typeError('Minimum Rating must be a number')
    .positive('Minimum Rating must be a positive number')
    .max(5, 'Minimum Rating must be less than or equal to 5')
    .required('Minimum Rating is required'),
  amount: Yup.number()
    .typeError('Amount must be a number')
    .positive('Amount must be a positive number')
    .required('Amount is required'),
    from: Yup.string().test('from', 'From is required', function(value) {
      const { duration } = this.parent;
      if (duration && duration.value === 'period') {
        return !!value;
      }
      return true;
    }),
    to: Yup.string().test('to', 'To is required', function(value) {
      const { duration } = this.parent;
      if (duration && duration.value === 'period') {
        return !!value;
      }
      return true;
    }),
});

export const minimumChargesBonusInitialValue = {
  amount :0
}

export const minimumChargesBonusInitialValueSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Amount must be a number')
    .min(0,'Amount must be a positive number')
    .required('Amount is required')
});

export const subscriptionDriverInitialValue = {
  name: "",
  duration: "",
  amount: ""
}

export const subscriptionDriverValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  duration: Yup.object().shape({
    value: Yup.string().required('Duration is required'),
    label: Yup.string().required('Duration is required'),
  }),
  amount: Yup.number()
    .typeError('Amount must be a number')
    .positive('Amount must be a positive number')
    .required('Amount is required'),
});

export const subscriptionFeeInitialValue = {
  subscriptionFee: 0,
  commissionFee: 0,
}

export const subscriptionFeeValidationSchema = Yup.object().shape({
  subscriptionFee: Yup.number()
    .typeError('subscription Fee must be a number')
    .min(0, 'subscription Fee must be a positive number or zero')
    .required('subscription Fee is required'),
  commissionFee: Yup.number()
    .typeError('commission Fee must be a number')
    .min(0, 'commission Fee must be a positive number or zero')
    .required('commission Fee is required'),
});

export const addCountryAndCityInitailValue = {
 name:""
}

export const addCountryAndCityValidationSchema = Yup.object().shape({
  name: Yup.mixed().required('required'),
});

export const addAdminInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  password: "",
}

export const addAdminInitialValuesValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('required'),
  lastName: Yup.string().trim().required('required'),
  email: Yup.string().email('Invalid email format').required('required'),
  password: Yup.string().trim().required('required'),
  country: Yup.object().shape({
    label: Yup.string().required('required'),
    value: Yup.string().required('required'),
  })
});

export const packageDefaultCategoryValidation = Yup.object().shape({
  category: Yup.string().trim().required('Category is required'),

  baseRate: Yup.number()
    .typeError('Base rate must be a number')
    .positive('Base rate must be a positive number')
    .required('Base rate is required'),

  perMiles: Yup.number()
    .typeError('Per Km must be a number')
    .positive('Per Km must be a positive number')
    .required('Per Km is required'),

  perMinute: Yup.number()
    .typeError('Per minute must be a number')
    .required('Per minute is required'),

  rideBookingFee: Yup.number()
    .typeError('Ride booking fee must be a number')
    .required('Ride booking fee is required'),

  cancellationFee: Yup.number()
    .typeError('Cancellation fee must be a number')
    .required('Cancellation fee is required'),

  platformFee: Yup.number()
    .typeError('Platform fee must be a number')
    .required('Platform fee is required'),

  cityTax: Yup.number()
    .typeError('City tax must be a number')
    .required('City tax is required'),

  weather: Yup.number()
    .typeError('Weather must be a number')
    .required('Weather is required'),

  noOfRides: Yup.number()
    .typeError('Number of rides must be a number')
    .integer('Number of rides must be an integer'),

});

export const rentalsDefaultCategoryValidation = Yup.object().shape({
  category: Yup.string().trim().required('Category is required'),

  cancellationFee: Yup.number()
    .typeError('Cancellation fee must be a number')
    .positive('Cancellation fee must be a positive number')
    .required('Cancellation fee is required'),

  platformFee: Yup.number()
    .typeError('Platform fee must be a number')
    .positive('Platform fee must be a positive number')
    .required('Platform fee is required'),

});

export const adminBannerInitialValues = {
  description: "",
  image: "",
}

export const adminBannerInitialValuesValidationSchema = Yup.object().shape({
  description: Yup.string().trim(),
  image: Yup.mixed().required('Image is required'),
});

export const rateInitialValues = {
  commissionFee:"", 
  discount:"",
  id:"",
}

export const rateValidationSchema = Yup.object().shape({
  commissionFee: Yup.number()
   .typeError('Commission fee must be a number')
   .positive('Commission fee must be a positive number')
   .required('Commission fee is required'),
  discount: Yup.number()
   .typeError('Discount must be a number')
   .positive('Discount must be a positive number')
   .required('Discount is required'),
  id: Yup.string().trim(),
});

export const rateRestaurantInitialValues = {
  deliveryFee:"", 
  tax:"",
  id:"",
}

export const rateRestaurantValidationSchema = Yup.object().shape({
  deliveryFee: Yup.number()
   .typeError('Delivery fee must be a number')
   .positive('Delivery fee must be a positive number')
   .required('Delivery fee is required'),
  tax: Yup.number()
   .typeError('tax must be a number')
   .positive('tax must be a positive number')
   .required('tax is required'),
  id: Yup.string().trim(),
});

export const salesAgentInitialValues = {
  rewardPercentage:0, 
  id:"",
}

export const salesAgentRatesValidationSchema = Yup.object().shape({
  rewardPercentage: Yup.number()
   .typeError('Delivery fee must be a number')
   .required('Delivery fee is required'),
  id: Yup.string().trim(),
});
